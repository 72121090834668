import Vue from 'vue';
import Slides from './slides.vue';
import Modal from './modal.vue';

document.addEventListener('DOMContentLoaded', function() {
  const vm = new Vue({
    el: '#viewer',
    components: {
      'cs-modal': Modal,
      'cs-slides': Slides
    },
    data: {
      chaserJSON: window.chaserJSON,
      currentChaser: 0,
      showModal: false
    },
    methods: {
      handleChaserClick: function(index) {
        this.currentChaser = index;
        this.showModal = true;
      }
    }
  })
});
