<template>
  <hooper :infiniteScroll="true" :wheelControl="true" :initialSlide="initialSlide" :transition="0">
    <slide v-for="(slide, indx) in slides" :key="indx" :index="indx">
      <div class="Slide">
        <img v-bind:src="slide.photo_url + '?fit=fill&w=2400&h=1663'" class="Slide-photo">
        <div class="ChaserCard">
          <figure class="ChaserCard-figure"><img v-bind:src="slide.portrait_url" v-bind:alt="slide.name"></figure>
          <div class="ChaserCard-details">
            <h3 class="ChaserCard-name">{{ slide.name }}</h3>
            <div class="PlatformLink">
              <a :href="slide.link" target="_blank" class="PlatformLink-link"><span class="offscreen">{{ slide.link_platform }}</span><svg role="img" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="PlatformLink-icon"><use :xlink:href="'#' + slide.link_platform" :href="'#' + slide.link_platform" /></svg><span class="PlatformLink-title">{{ slide.link_title }}</span></a>
            </div>
          </div>
        </div>
      </div>
    </slide>
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
  </hooper>
</template>

<script>
  import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
  import 'hooper/dist/hooper.css';

  export default {
    name: 'Slides',
    props: {
      slides: {
        type: Array,
        required: true
      },
      initialSlide: {
        type: Number,
        required: true
      }
    },
    components: {
      Hooper,
      Slide,
      HooperNavigation
    }
  }
</script>

<style>
  .hooper {
    height: 100%;
  }
  .hooper-next, .hooper-prev {
    /* image is 899 x 623 when vw is 1033
     * so height should be half image height: 623/2;
    */
    top: calc(311.5*100/1033 * 1vw);
  }
  .hooper-next svg, .hooper-prev svg{
    fill: #fff;
    opacity: 0.8;
    height: 32px;
    width: 32px;
  }
  .Slide-photo {
    width: 100%;
  }
  @media (min-width: 1109px) {
    .hooper-next, .hooper-prev {
      top: 334px;
    }
  }

</style>
